@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200&display=swap');

$sitecolour: #6e52a0;
$pagewidth: 80%;

html, body {
    background: #191919;
    font-family: 'Inter', sans-serif;
    margin: 0;
    height: 100%;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.header {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    font-family: inherit;
}

.header__flex__wrapper {
    margin: 0;
    max-width: $pagewidth;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    padding-left: 0;
    padding-right: 0;
}

.header__content__left {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
}

.header__logo {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1rem;
    padding: 0.5rem;
    padding-left: 0;
    padding-right: 0;
}

.header__logo a {
    padding-right: 0!important;
}

.header__logo:hover {
    color: #fff;
    cursor: pointer;
}

.header__menu__left {
    margin-left: 1vh;
    align-items: center;
}

.header a {
    padding: 0.5rem;
    color: white;
    text-decoration: none;
}
.header a:hover {
transition: 0.2s;
	color: #8c76b4;
}

.header .active {
    color: $sitecolour;
}

.header__menu__right {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.header__menu__right > a {
    font-weight: 700;
}

.header .button__primary {
    margin-left: .5rem;
    padding: .5 1rem;
    border-radius: 1px;
}

.button__primary {
background-image: url(https://static.cracked.to/images/thead_bg.png);
background-size: cover;
    background-color: #6e52a0;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.button__primary:hover {
    transition: all 0.2s ease-in-out;
	background-color: #8c76b4;
}

.product__image_wrapper {
    width: 100%;
}

.product__image_wrapper > img {
    max-height: 23vh!important;
    max-width: 100%;
    width: 100%;
}

.index__content__wrapper {
    width: $pagewidth;
    max-width: $pagewidth;
    margin-left: auto;
    margin-right: auto;
}

.rf-large-title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.05;
    margin-bottom: 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: block;
}

.fw-700 {
    font-weight: 700;
}

.theme-colour {
    color: $sitecolour;
    font-weight: 700;
    text-decoration: none;
}

.search__wrapper {
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: rgb(178, 186, 194);
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.5rem;
    padding-right: 4rem;
    width: fit-content;
    border-radius: 1px;
}

.input__wrapper {
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: rgb(178, 186, 194);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;

    width: fit-content;
    border-radius: 1px;
    width: 100%;
}

.input__wrapper > input, .input__wrapper > select {
    margin-left: 1vw;
    background: none;
    border: none;
    font-size: 1rem;
    color: rgb(178, 186, 194);
}

.input__wrapper > select {
    color: rgba(178, 186, 194, .5);
}

.input__wrapper > input::placeholder, .input__wrapper > select::placeholder {
    color: rgba(178, 186, 194, .5);
    font-family: 'Inter', sans-serif;
}

.input__wrapper > input:focus, .input__wrapper > select:focus {
    outline: none;
}

.mr-2 {
    margin-right: 0.5rem;
}

.hot__products__wrapper {
    padding-top: 2rem;
}

.rf-title {
    background: #151515;
    border: 1px solid rgba(255, 255, 255, 0.07);
    font-size: 13px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    margin-top: 2.25rem;
    display: block;
    width: fit-content;
    text-transform: uppercase;
    padding: .7rem .9rem;
    border-radius: 1px;
}

.product {
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 1px;
    background-color: #171717;
    margin-bottom: 1rem;
    width: 270px;
    margin: 20px;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0;
}

//products__row has 4 products in it
.products__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0;
}

.product__wrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    color: white;
}

.product__image_wrapper > img {
    max-width: 100%;
    border-radius: 1px;
}

// product__content__wrapper contains product__title and product__price, they should be on the same line with product__title on the left and product__price on the right

.product__content__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;
    margin-bottom: 1rem;
    width: 100%;
}

.product__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.1;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: block;
    width: fit-content;
    padding: 0.5rem;
    padding-bottom: 0;
}

.product__price {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.1;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: block;
    width: fit-content;
    text-transform: uppercase;
    padding: 0.5rem;
    padding-bottom: 0;
    text-decoration: underline;
}

.product__button {
    width: 100%;
    font-family: 'Inter';
    font-family: 'Inter';
    padding: .5 1rem;
    line-height: 1.5;
    border-radius: 1px;
    background: #6e52a0;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 90%;
}

.auth__button__primary {
background-image: url(https://static.cracked.to/images/thead_bg.png);
background-size: cover;
    width: 100%;
    font-family: 'Inter';
    padding: 0.5rem 1rem;
    line-height: 1.5;
    border-radius: 1px;
}

.auth__content__wrapper {
    width: 30%;
    max-width: 30%;
    margin-left: auto;
    margin-right: auto;
}

.auth__title {
    text-align: center;
    padding-bottom: 3vh;
}

.auth__link {
    text-align: center;
    color: white;
    padding-top: 3vh;
    display: block;
}

.error__wrapper {
    background: #c30101;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 02.5px;
    line-height: 1.5;
    font-weight: 500;
    font-family: "Inter";
}

// .product__title {
//     font-size: 1rem;
//     font-weight: 700;
//     line-height: 1.1;
//     margin-bottom: .25rem;
//     color: #fff;
//     font-family: 'Montserrat', sans-serif;
//     margin-top: .5rem;
//     display: block;
//     width: fit-content;
//     text-transform: uppercase;
//     padding: 0.5rem;
//     padding-bottom: 0;
// }

// .product__price {
//     font-size: 1rem;
//     font-weight: 700;
//     line-height: 1.1;
//     color: #fff;
//     font-family: 'Montserrat', sans-serif;
//     display: block;
//     width: fit-content;
//     text-transform: uppercase;
//     padding: 0.5rem;
//     padding-top: 0;
//     padding-bottom: 0;
// }

.loading__content__wrapper {
    text-align: center;
}

.loading__content__icon {
    font-size: 2rem;
    color: white;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5)!important;
}

.captcha__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.1;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 1rem;
    display: block;
}

.rf-icon {
    font-size: 4.5rem;
    color: $sitecolour;
    margin-bottom: 1rem;
}

.rf-subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 1rem;
    display: block;
    margin-top: .5rem;
}

.cart__empty__container {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.cart__content__wrapper {
    display: flex;
}

.cart__content__wrapper > div {
    width: 70%;
}

.cart__content__wrapper > div:nth-child(2) {
    margin-left: 2.5%;
    width: 27.5%;
}

.cart__products__wrapper {

}

.cart__checkout__wrapper {
    background: #171717;
    margin-top: 7.5px;
    padding: 1rem;
    min-height: fit-content!important;
}

.cart__checkout__wrapper .rf-subtitle {
    margin-top: 0;
}

// .cart__product__wrapper {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     background: #171717;
//     border: 1px solid rgba(255, 255, 255, 0.07);
//     border-radius: 1px;
//     padding: 1rem;
// }

.cart__table {
    width: 100%;
    border-spacing: 0 7.5px;
    text-align: left;
    border-radius: 1px;
    border-collapse: separate;
}

.card__table__header {
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 1px;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.75);
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.01rem;
    padding-bottom: 1rem;
}

.card__table__header th {
    padding: 1rem;
}

.card__table__header th:last-of-type {
    text-align: right;
}

.card__table__row {
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 1px;
    padding: 1rem;
    color: rgba(255, 255, 255, 1);
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.01rem;
}

.card__table__row td {
    padding: 1rem;
}

.card__table__row td:last-of-type {
    text-align: right;
}

.cart__table button {
    width: fit-content!important;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 600;
}

.mt-2 {
    margin-top: 1.5rem;
}

.button__danger {
    background: #c30101;
}

.button__primary__border {
    background: transparent;
    border: 1px solid $sitecolour;
    color: $sitecolour;
}

table th:first-child{
    border-radius: 3x 0 0 3x;
}
  
table th:last-child{
    border-radius: 0 3px 3px 0;
}

table tr:first-child td:first-child{
    border-radius: 3px 0 0 0;
}

table tr:first-child td:last-child{
    border-radius: 0 3px 0 0;
}

table tr:last-child td:first-child{
    border-radius: 0 0 0 3px;
}

table tr:last-child td:last-child{
    border-radius: 0 0 3px 0;
}

.cart__quantity__edit {
    width: 100px;
    background-color: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 600;
    padding: 0.5rem;
}

.cart__checkout__content__row {
    display: block;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    padding-bottom: 1rem;
}

.cart__checkout__content__row__title {
    font-size: 10px;
    font-weight: 700;
    line-height: 1.1;
    color: rgba(255, 255, 255, 0.75);
    font-family: 'Montserrat', sans-serif;
    display: block;
    width: fit-content;
    text-transform: uppercase;
    padding: 0.5rem;
    padding-left: 0;
    padding-bottom: 0;
}

.cart__checkout__content__row__value {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.1;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: block;
    width: fit-content;
    text-transform: uppercase;
    padding: 0.5rem;
    padding-left: 0;
    padding-bottom: 0;
    text-decoration: underline;
    font-size: 1.5rem;
}

.cart__checkout__content__row__select {
    width: 100%;
    background-color: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: .75rem;
    margin-top: .5rem;
    font-weight: 600;
    padding: 1rem .5rem;
}

.modal__image_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__image_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1px;
}

.modal__header {
    display: flex;
    justify-content: space-between;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    padding-bottom: .75rem;
}

.modal__header span {
    font-family: 'Montserrat', sans-serif;
}

.modal__header button {
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    margin-top: -.35rem;
    padding: 0;
}

.modal__content_wrapper {
    color: white;
    padding: 1rem 0rem;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
}

.modal__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    padding-top: .75rem;
    margin-top: .4rem;
}

button:disabled {
    border: none;
    cursor: not-allowed;
    background: rgba($sitecolour, 0.5);
}

.order__status__container {
    text-align: center;
}

.order__status {
    //createa badge
    width: fit-content;
    padding: .5rem 1rem;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    border-radius: 1px;
    color: #fff;
    background-color: #6c757d;
}

.order__status__cancelled {
    background-color: #dc3545;
}

.order__status__completed {
    background-color: #06BA63;
}

.order__status__processing {
    background-color: #F35B04;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgba(25, 25, 25, 0.93) inset !important;
    -webkit-text-fill-color: rgb(178, 186, 194) !important;
}

.search__input__wrapper {
    width: fit-content;
    padding-top: .9rem;
    padding-bottom: .9rem;
    padding-left: 1.25rem;
    padding-right: 8rem;
}


.index__hero__content {

    width: $pagewidth;
    max-width: $pagewidth;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.5rem;
	padding-right: 2.5rem;
	padding-top: 10rem;
	padding-bottom: 10rem;
    background: #171717;
	background-image: url("https://i.imgur.com/9AwDU1G.png");
	background-size: cover;
    border-radius: 10px;
    padding-top: 3rem;
    padding-bottom: 3rem;

}

.index__hero__content .input__wrapper {
    margin-bottom: 0!important;
}

.footer {
    margin-top: 3rem;
	padding-bottom: 20px;
    background: #171717;
}

.footer__container {
    width: $pagewidth;
    max-width: $pagewidth;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background: #171717;
    border-top-left-radius: 2.5px;
    border-top-right-radius: 2.5px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: 1px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.footer__container__left {
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.footer__logo {
    font-family: "Montserrat", sans-serif;
    color: $sitecolour;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1rem;
}

.footer__description {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 1rem;
}

.footer__socials {
    margin-top: 1rem;
}

.footer__socials a {
    color: #fff;
    font-size: 1.5rem;
    margin-right: 1rem;
}

.footer__socials a:hover {
    color: $sitecolour;
}

.footer__container__right {
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
    text-align: right!important;
}

.footer__links a {
    display: block;
}

.footer__links__title {
    color: $sitecolour;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-family: "Montserrat", sans-serif;
}

.footer__links__content a {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
}

.footer__links {
    padding: 0 1rem 0 1rem;
}

.page__wrapper {
    min-height: 80vh;
}

.header__logo__image {
    width: 190px;
}
.header__logo__image:hover {
    transition: 0.2s;
	opacity: 0.5;
}

.index__search__elements {
    display: flex;
}

.category__selector__options {
    position: absolute;
    background-color: #171717;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: rgb(178, 186, 194);
    border-radius: 1px;
    margin-left: -21px;
    margin-top: -15px;
}

.category__selector__option {
    width: 100%;
}

.category__selector__options > div {
    margin: 0;
    border: 0;
    border-radius: 0;
}

.category__selector__option:hover {
    cursor: pointer;
}

.order__await__container {
    background: #171717;
    color: #fff;
    border-radius: 2.5px;
    min-height: 50vh;
}

.order__await__left {
    width: 17.5%;
    max-width: 17.5%;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.03);
}

.order__await__left > * {
    display: block;
}

.order__await__right {
    width: 82.5%;
    max-width: 82.5%;
    padding: 2rem;
    text-align: center;
}

.order__data__title {
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
}

.order__data__value {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.order__await__container {
    display: flex;
}

.order__data__info__title {
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
}

.order__data__info__value {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    display: block;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

//mobile responsive version

//1300px

@media screen and (max-width: 1300px) {
    body, .index__hero__content {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
    }

    .header__flex__wrapper {
        width: 90%;
        max-width: 90%;
    }

    .index__content__wrapper {
        width: 90%;
        max-width: 90%;
    }

    .header__logo__image {
        width: 150px;
    }

    .products__row {
        display: block;
    }

    .product {
        width: 100%!important;
        margin-bottom: 1rem!important;
    }

    .input__wrapper {
        padding-right: 3rem;
        max-width: 100%;
    }
	.category__selector__options {
	width: 100%;
	}

    .header__menu__left {
        display: none;
    }

    .ReactModal__Content {
        width: 100%!important;
        max-width: 100%!important;
    }

    .auth__content__wrapper {
        width: 90%;
        max-width: 90%;
    }
    
    .auth__title {
        margin-top: 3vh;
    }

    .cart__content__wrapper {
        display: block;
    }

    .cart__products__wrapper {
        width: 100%;
        max-width: 100%;
    }

    .button__text {
        display: none;
    }

    button.mr-2 {
        margin-right: 0!important;
        width: fit-content!important;
    }

    .card__table__row button > i.mr-2 {
        margin-right: 0!important;
    }

    .cart__checkout__wrapper, .cart__products__wrapper {
        width: 100%!important;
        max-width: 100%!important;
        margin-left: 0!important;
    }

    .footer__container {
        width: 100%;
        max-width: 100%;
    }

    .footer__container__left {
        width: 100%;
        margin-left: 0!important;
    }

    .footer__container__right {
        display: none;
    }

    .index__search__elements {
        display: block;
    }

    .index__search__elements > * {
        width: 100%;
        min-width: 100%;
    }
}
